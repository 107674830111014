<template>
    <div>
      <a-alert
        v-if="errorMessage"
        type="error"
        :message="errorMessage"
        banner
        closable
        @close="errorMessage = null"
      />
      <div class="row"><div class="col-lg-12"></div></div>
      <div class="row">
        <div class="col-md-12">
          <filter-regional class="mr-2" v-model:value="region" />
          <filter-province class="mr-2 mt-2" v-model:value="provinsi" v-model:region="region" />
          <filter-area class="mt-2" v-model:value="area" v-model:provinsi="provinsi" />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <filter-kabupaten-kota
            class="mr-2 mt-2"
            v-model:value="kabupaten"
            v-model:kabupaten="kabupaten"
            v-model:area="area"
          />
          <filter-products class=" mr-2 mt-2" v-model:value="product" v-model:product="product" />
          <filter-distributor
            class="mr-2 mt-2"
            v-model:value="distributor"
            v-model:distributor="distributor"
            v-model:region="region"
            v-model:area="area"
            v-model:provinsi="provinsi"
            v-model:kabupaten="kabupaten"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <filter-segment
            class="mr-2 mt-2"
            :mode="null"
            style="width: 300px;"
            placeholder="Pilih Tipe Customer"
            v-model:value="segment"
          />
          <a-date-picker
            style="width: 300px;"
            class="mr-2 mt-2"
            :disabled-date="disabledStartDate"
            v-model:value="start_date"
            placeholder=" Tanggal Awal"
            format="DD MMMM YYYY"
          />
  
          <a-date-picker
            style="width: 300px;"
            class="mr-2 mt-2"
            :disabled-date="disabledEndDate"
            v-model:value="end_date"
            placeholder=" Tanggal Akhir"
            format="DD MMMM YYYY"
          />
  
          <a-input-search
            class="mr-2 mt-2"
            v-model:value="q"
            placeholder="Cari ..."
            style="width: 300px"
          />
  
          <a-button class="mt-2" type="primary" title="cari" @click="search" :loading="isFetching">
            <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
            <span v-else>Memuat Data ...</span>
          </a-button>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-lg-6 col-md-12"></div>
        <div class="col-lg-6 col-md-12 text-right">
          <a-button
            class="mr-3"
            title="Download Excel"
            type="primary"
            @click="fetchXlsx"
            :loading="isDownloading"
          >
            <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
            <span v-else>Downloading ...</span>
          </a-button>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card-placeholder">
            <div class="card-body mt-2">
              <div class="table-responsive ">
                <md-table
                  :columns="columns"
                  :data-source="data"
                  :row-selection="rowSelection"
                  size="small"
                  row-key="id"
                  :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) =>
                      `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: meta.totalCount,
                    pageSize: meta.perPage,
                    current: meta.currentPage,
                  }"
                  :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                  @change="handleTableChange"
                  :loading="isFetching"
                >
                  <template #filterIcon="{ text: filtered }">
                    <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
                  </template>
  
                  <template #customer="{ text }">
                    <span v-if="searchText">
                      <template
                        v-for="(fragment, i) in text
                          .toString()
                          .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
                      >
                        <mark
                          v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                          :key="i"
                          class="highlight"
                          >{{ fragment }}</mark
                        >
                        <template v-else>{{ fragment }}</template>
                      </template>
                    </span>
                    <template v-else>
                      <a class="btn btn-sm btn-light" href="javascript: void(0);">{{ text }}</a>
                    </template>
                  </template>
                  <template #progress="{ text: bar }">
                    <div class="progress">
                      <div
                        :class="['progress-bar', bar.color]"
                        :style="{ width: bar.value + '%' }"
                        role="progressbar"
                      ></div>
                    </div>
                  </template>
                  <template #no="{ index }">
                    <span>
                      {{ startRow + index }}
                    </span>
                  </template>
                  <template #value="{ text }">
                    <span class="font-weight-bold">{{ text }}</span>
                  </template>
                  <template #id="{ text }">
                    <a href="javascript: void(0);" class="btn btn-sm btn-light">{{ text }}</a>
                  </template>
                  <template #total="{ text }">
                    <span>${{ text }}</span>
                  </template>
                  <template #tax="{ text }">
                    <span>${{ text }}</span>
                  </template>
                  <template #shipping="{ text }">
                    <span>${{ text }}</span>
                  </template>
                  <template #nilai="{ text }">
                    <span>{{ text ? new Intl.NumberFormat("id-ID").format(text) : 0 }}</span>
                  </template>
                </md-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent, ref, watch, computed, onMounted, reactive, provide, toRefs } from 'vue'
  import apiClient from '@/services/axios'
  // import MModal from './modal'
  import FilterRegional from '@/components/filter/FilterRegional'
  import FilterDistributor from '@/components/filter/FilterDistributor'
  import FilterProvince from '@/components/filter/FilterProvince'
  import FilterArea from '@/components/filter/FilterArea'
  import FilterKabupatenKota from '@/components/filter/FilterKabupatenKota'
  // import FilterDistributor from '@/components/filter/FilterDistributor'
  import FilterSegment from '@/components/filter/FilterSegment'
  import FilterProducts from '@/components/filter/FilterProduct'
  import { useDisabledDate } from '@/composables/DisabledDate'
  import qs from 'qs'
  import moment from 'moment'
  
  const acolumns = [
    {
      title: 'NO',
      slots: { customRender: 'no' },
    },
    {
      title: 'Tanggal Transaksi',
      dataIndex: 'tgl_trx',
      // width: 100,
    },
    // {
    //   title: 'Kode Distributor',
    //   dataIndex: 'distri_code',
    //   // align: 'center',
    // },
    // {
    //   title: 'Nama Distributor',
    //   dataIndex: 'vendor_name',
    //   width: 160,
    // },
    //  {
    //   title: 'Distributor',
    //   dataIndex: 'distri',
    //   width: 200,
    // },
    {
      title: 'ID Toko',
      dataIndex: 'vendor_code',
      // width: 100,
    },
    {
      title: 'Nama Toko',
      dataIndex: 'nama_toko',
      // width: 150,/
    },
    {
      title: 'Regional Toko',
      dataIndex: 'reg_toko',
      // width: 120,
    },
    {
      title: 'Provinsi Toko',
      dataIndex: 'prov_toko',
      // width: 120,
    },
    {
      title: 'Area Toko',
      dataIndex: 'area_toko',
      // width: 120,
    },
    {
      title: 'Kabupaten/Kota Toko',
      dataIndex: 'kab_toko',
    },
    {
      title: 'No Transaksi',
      dataIndex: 'no_so',
    },
    {
      title: 'Kode Distributor',
      dataIndex: 'distri_code',
    },
    {
      title: 'Nama Distributor',
      dataIndex: 'vendor_name',
    },
    {
      title: 'Kode Produk',
      dataIndex: 'kode_produk',
      // width: 150,
    },
    {
      title: 'Nama Produk',
      dataIndex: 'produk_name',
      // width: 200,
    },
    {
      title: 'Brands',
      dataIndex: 'brand',
      // width: 150,
    },
    {
      title: 'UOM ',
      dataIndex: 'uom',
      // width: 100,
    },
    {
      title: 'Zak Quantity ',
      dataIndex: 'zak_qty',
    },
    {
      title: 'TON Quantity ',
      dataIndex: 'ton_qty',
    },
    {
      title: 'Harga ',
      dataIndex: 'price',
      slots: { customRender: 'nilai' },
    },
    {
      title: 'Total Harga ',
      dataIndex: 'total_price',
      slots: { customRender: 'nilai' },
    },
    {
      title: 'Kode Gudang ',
      dataIndex: 'kode_gudang',
    },
    {
      title: 'Nama Gudang ',
      dataIndex: 'nama_gudang',
      // width: 180,
    },
    {
      title: 'Regional Gudang',
      dataIndex: 'reg_gudang',
    },
    {
      title: 'Provinsi Gudang',
      dataIndex: 'prov_gudang',
    },
    {
      title: 'Area Gudang',
      dataIndex: 'area_gudang',
    },
    {
      title: 'Kota / Kabupaten Gudang',
      dataIndex: 'kab_gudang',
    },
    {
      title: 'Tipe Customer',
      dataIndex: 'type_name',
    },
    {
      title: 'Cluster Pareto',
      dataIndex: 'cluster_name',
    },
    {
      title: 'SSM',
      dataIndex: 'user_ssm',
      title_xls: 'SSM',
    },
    {
      title: 'ASM',
      dataIndex: 'user_sm',
      title_xls: 'ASM',
    },
    {
      title: 'TSO',
      dataIndex: 'user_am',
      title_xls: 'TSO',
    },
  ]
  
  export default defineComponent({
    name: 'VbDashboard',
    components: {
      // MModal,
      FilterRegional,
      FilterProvince,
      FilterArea,
      FilterKabupatenKota,
      FilterDistributor,
      FilterProducts,
      FilterSegment,
    },
    setup() {
      const perPage = ref(10)
      const pageCount = ref(0)
      const totalCount = ref(0)
      const meta = ref({})
      const page = ref(1)
      const searchText = ref('')
      const searchInput = ref(null)
      const columns = ref([])
      const data = ref([])
      const current1 = ref(1)
      const q = ref('')
      const segment = ref([])
      const region = ref([])
      const provinsi = ref([])
      const kabupaten = ref([])
      const product = ref([])
      // const brands = ref([])
      const distributor = ref([])
      const area = ref([])
      const dataProduct = ref([])
      const errorMessage = ref(null)
      const {
        start_date,
        end_date,
        startDate,
        endDate,
        disabledStartDate,
        disabledEndDate,
        firstDayMonth,
        lastDayMonth,
      } = useDisabledDate()
      start_date.value = moment(new Date()).startOf('month')
      end_date.value = moment(new Date())
  
      columns.value = acolumns
  
      const params = ref({})
      const fetchData = () => {
        const _params = {
          page: page.value,
          'per-page': perPage.value,
          region: region.value,
          area: area.value,
          provinsi: provinsi.value,
          kabupaten: kabupaten.value,
          product: product.value,
          type: segment.value,
          // brand: brands.value,
          // distributor: distributors.value,
          start_date: startDate.value,
          end_date: endDate.value,
          distributor: distributor.value,
          q: q.value,
        }
        params.value = Object.assign({}, _params)
        state.isFetching = true
        apiClient
          .get('api/report-brand/trx-toko-harian', {
            params: _params,
          })
          .then(response => {
            const { items, _meta } = response.data
            data.value = items
            meta.value = _meta || { pageCount: 0, totalCount: 0 }
            pageCount.value = meta.value.pageCount
            totalCount.value = meta.value.totalCount
          })
          .finally(() => {
            state.isFetching = false
          })
      }
     const fetchXlsx = () => {
        state.isDownloading = true
        apiClient
          .get('api/report-brand/trx-toko-harian', {
            params: {
              ...params.value,
              _export: 'xls',
              _columns: columns.value
                .filter(c => c.dataIndex !== null && c.dataIndex !== undefined)
                .map(({ dataIndex: key, title, title_xls }) => ({
                  key,
                  title: title_xls ? title_xls : title,
                })),
            },
            paramsSerializer: function(params) {
              return qs.stringify({ ...params }, { arrayFormat: 'indices' })
            },
            responseType: 'blob',
            headers: {},
          })
          .then(response => {
            const url = URL.createObjectURL(
              new Blob([response.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }),
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute(
              'download',
              `transaksi-id-toko-by-product-distributor_${moment().format('DDMMYY_HHmm')}.xlsx`,
            )
            document.body.appendChild(link)
            link.click()
          })
          .catch(async error => {
            errorMessage.value = null
            if (error.response) {
              const { status, statusText } = error.response
              const message = JSON.parse(await error.response.data.text()).message
              errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
            }
          })
          .finally(() => {
            state.isDownloading = false
          })
      }
      const startRow = computed(() => (page.value - 1) * perPage.value + 1)
      watch(meta, (newValue, oldValue) => {
        page.value = newValue.currentPage
        perPage.value = newValue.perPage
      })
      const handleTableChange = (pag, filters, sorter) => {
        page.value = pag.current
        perPage.value = pag.pageSize
        fetchData()
        startRow.value = (page.value - 1) * perPage.value + 1
      }
      const onSelectChange = selectedRowKeys => {
        state.selectedRowKeys = selectedRowKeys
      }
      const state = reactive({
        selectedRowKeys: [],
        loading: false,
        isFetching: false,
        isDownloading: false,
      })
  
      const fetchDataProduct = products => {
        apiClient.get('/api/filter/product', { params: { q: products } }).then(response => {
          dataProduct.value = response.data
        })
      }
  
      const handleSearch = (selectedKeys, confirm) => {
        confirm()
        searchText.value = selectedKeys[0]
      }
  
      const handleReset = clearFilters => {
        clearFilters()
        searchText.value = ''
      }
      onMounted(() => {
        fetchData()
        fetchDataProduct()
      })
  
      const search = () => {
        fetchData()
      }
  
      return {
        q,
        searchText,
        searchInput,
        data,
        columns,
        handleReset,
        handleSearch,
        current1,
        perPage,
        totalCount,
        pageCount,
        page,
        meta,
        handleTableChange,
        startRow,
        region,
        provinsi,
        kabupaten,
        product,
        segment,
        area,
        // brands,
        onSelectChange,
        state,
        fetchXlsx,
        // distributors,
        fetchData,
        ...toRefs(state),
        start_date,
        end_date,
        search,
        dataProduct,
        errorMessage,
        disabledStartDate,
        disabledEndDate,
        firstDayMonth,
        lastDayMonth,
        distributor,
      }
    },
  })
  </script>
  